import React from "react";
// import {Link} from 'react-router-dom';

import LightSpeed from 'react-reveal/LightSpeed';
import TextLoop from "react-text-loop";
// import EarlyAccessForm from '../reuse/form/early_access';


export default class HeroSection extends React.Component {

  render (){
    const hero = {
      image: {
        // https://icons8.com/illustrations/style--pixeltrue
        path: '/static/images/hero.svg',
        alt: 'Keep a check on activities important to you',
      },
    }

    return (
      <section id="hero" className=" my-5">
        <HeroTitleSubFeaturesImageComponent hero={hero} />
      </section>
    )
  }
}

function HeroTitleSubFeaturesImageComponent(props){
  const is_mobile = window.innerWidth < 800;
  const hero = props.hero;
  return (
    <div className="my-5">

      <div className="row m-0 black-text-3">
      <div className=" col-md-6 col-sm-12">

      <div className="text-center my-3 ">
        <div className={"my-3 py-5 strong " + ((is_mobile)? ' px-1 ': '  p-2 ' )}>
          <h1 className={"  " + ((is_mobile)? ' text-size-10 ': ' text-size-14  ' )}>
            <span className="primary-text-1 ">Track </span>
            <span className="black-text-1"> recurring activities</span>
          </h1>

          {/* <h5 className={"black-text-6 py-3" + ((is_mobile)? ' text-size-4 ': ' text-size-6 ' )}>
            <span className="">.. and build long term </span>
            <span className="">habits</span>
          </h5> */}
        </div>
      </div>

        <div className="px-3">

        <div className={"my-3 pb-3 text-size-4" + ((is_mobile)? ' pl-1 ': ' pl-5 ' )}>

          {/* <div className="text-left  ">
            
            <TextLoop interval={4000} delay={1000}>
              <span>. check my weight ⚖ every week</span>
              <span>. check financial goals 💰 every month</span>
              <span>. make bill payments 💵 every month</span>
              <span>. 🎊 be reminded of anniversaries every year</span>
              <span>. 🎂 be reminded of birthdays every year</span>

              Monthly cleanup of AWS container service images

            </TextLoop>
          </div> */}

          <span className="text-muted"> I'd like to: </span>

          <div className="text-left py-1 pl-4 ">
            <TextLoop interval={4000} delay={1000}>
              <span>. sleep <span className="" role='img' aria-label=''>😴</span> 8 hours</span>
              <span>. drink <span className="" role='img' aria-label=''>💧</span> 8 glasses of water</span>
              <span>. walk <span className="" role='img' aria-label=''>🚶‍♀️</span> 2 km </span>
              {/* <span>. reading time 📖</span> */}
              {/* <span>. meals 🍲</span> */}
            </TextLoop>
            <span className="text-primary"> daily</span> 
          </div>
          <div className="text-left py-1 pl-4 ">
            <TextLoop interval={4000} delay={2000}>
              <span>. read <span className="" role='img' aria-label=''>📖</span> 5 hours </span>
              <span>. call <span className="" role='img' aria-label=''>📞</span> 1 friend</span>
              <span>. learn <span className="" role='img' aria-label=''>📖</span> 1 python module</span>
              <span>. workout <span className="" role='img' aria-label=''>🏋</span> 3 times</span>
              <span>. cycle <span className="" role='img' aria-label=''>🚲</span> 80 kms</span>
              {/* <span>. weight ⚖</span> */}
              {/* <span>. tv time 📺</span> */}
              {/* <span>. office meetings time 🏢</span> */}
              {/* <span>. house keeping chores 🏠</span> */}
            </TextLoop>
            <span className="text-primary"> weekly</span> 
          </div>
          <div className="text-left py-1 pl-4 ">
            
            <TextLoop interval={4000} delay={3000}>
              <span>. write <span className="" role='img' aria-label=''>🖊</span> 4 blogs </span>
              <span>. cook <span className="" role='img' aria-label=''>👨‍🍳</span> 5 meals </span>
              <span>. check in with <span className="" role='img' aria-label=''>👨‍👨‍👦</span> 1 relative </span>
              {/* <span>. bill payments 💵</span> */}
              {/* <span>. financial goals 💰</span> */}
            </TextLoop>
            <span className="text-primary"> monthly</span> 
          </div>
          <div className="text-left py-1 pl-4 ">
            
            <TextLoop interval={4000} delay={4000}>
              <span>. go on family vacation <span className="" role='img' aria-label=''>👨‍👨‍👧‍👦</span> 2 times </span>
              <span>. do a good deed for strangers <span className="" role='img' aria-label=''>👏</span> 3 times</span>
              <span>. hike <span className="" role='img' aria-label=''>👟</span> on 15 trails </span>
              {/* <span>. anniversaries 🎊</span> */}
              {/* <span>. birthdays 🎂</span> */}
            </TextLoop>
            <span className="text-primary"> yearly</span> 
          </div>

          {/* <h5 className={"text-left  "+ ((is_mobile)? ' pt-5 pb-2 my-2': " py-5 my-5")}>
            Get insightful analytics automatically! This allows for a better shot at long term habit forming.

          </h5> */}
        </div>

        <div className="my-5 text-center">
          <div className="py-5 my-5">
            <LightSpeed left>
              {/* <EarlyAccessForm full_width={true} /> */}
              <a 
                className={" btn-tertiary white-text-1 rounded py-2 text-spacing-1 px-5 " + ((is_mobile)? ' text-size-8 ': ' text-size-10 ')}
                href="https://bytetrack-app.bytebeacon.com/login">
                Start tracking now!
                <i className="fas fa-paper-plane ml-4"></i>
              </a>
              <div className="my-2 text-size-2 black-text-6">* No credit card required</div>
            </LightSpeed>
          </div>
        </div>

        </div>
      </div> 


      {(is_mobile)? (''):(
        <div className=" col-md-6 col-sm-12" style={{display: 'flex',alignItems: 'center',justifyContent: 'center',}}>
          {/* <img className="hero" src={hero.image.path} alt={hero.image.alt} /> */}
          <div className="" dangerouslySetInnerHTML={{__html: `<SlickVid data-video-id="bytetrack" data-thumbnail="12" ${`data-size="${Math.min(500, (window.innerWidth-50))}"`}></SlickVid>`}}/>

        </div> 
      )}

      </div>

    </div>
  )
}
