import React from 'react';

import {SectionTitle} from 'component/reuse/title';
import EarlyAccessForm from '../reuse/form/early_access';


export default class EarlyAccessCTA extends React.Component {
  render (){
    const text_colour = this.props.text_colour || 'primary';
    return (
      <section id="early_access" className="content-section flex-center ">
        <div className="container-fluid my-5">
          <SectionTitle first="" second="Signup for early access" background={text_colour} />
          <div className="my-5">
            <EarlyAccessForm />
          </div>
        </div>
      </section>
    )
  }
}