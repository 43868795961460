import React from 'react';
import alertify from 'alertifyjs';

import {SubscriptionAPI, handleError} from 'utils/xhr';


export default class Form extends React.Component {

  state = {
    email: '',
  }

  onSubscribe(e){
    e.preventDefault();

    const url = '/public/subscribe';
    const data = {'email': this.state.email}
    SubscriptionAPI.post(url, data).then(response =>{
      const state = this.state;
      state.email = '';
      alertify.warning('Request submitted!', 1.5);  
      this.setState(state);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onChange(e){
    const state = this.state;
    state.email = e.target.value;
    this.setState(state);
  }
  
  render (){
    const force_full_width = this.props.full_width || false;

    return (
      <div className="flex-center">
        <div className={"input-group " + ((force_full_width)? ' col-12 ':' col-md-6 ')}>
          <input 
            className="form-control btn-rounded py-3 small" 
            type="email" 
            placeholder="Email address"
            
            onChange={this.onChange.bind(this)}
            value={this.state.email} />
          <div className="input-group-append">
            <a className="btn input-group-text btn-rounded btn-lg bg-tertiary text-white" style={{border: '0px'}} href='/' onClick={this.onSubscribe.bind(this)} >Get early access</a>
          </div>
        </div>
      </div>
    )
  }
}
