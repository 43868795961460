export const PROFILE = {
  "contact": {
    "twitter": "https://twitter.com/bytebeacon",
    "linkedin": "https://www.linkedin.com/company/bytebeacon",
    "github": "https://github.com/bytebeacon",
    "facebook": "https://www.facebook.com/ByteBeacon-109146270805201",
// // "https://www.quora.com/profile/"+contact.quora
// // "https://www.instagram.com/"+contact.instagram
// // "https://hub.docker.com/r/"+contact.docker
  }
};



export const SITE = {
  url: "https://bytebeacon.com",
  title: "ByteBeacon | Your digital growth beacon",
  description: "Building self-serve growth tools for online SaaS business",
  logo: "https://bytetrack.bytebeacon.com/logo.svg",
}