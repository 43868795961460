import React from 'react';
import { withRouter} from 'react-router-dom';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import LandingLayout from 'component/page/landing';
import EarlyAccessCTA from 'component/content/early_access';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="404-page">

        <div className="container my-5">
          <div className="row">
            <div className="col-2 "></div>
            <div className="col-8 ">

              <h1 className="text-center">Coming soon!</h1>
              <div className="text-center my-5">
                <img 
                  className="lazyload img img-fluid" 
                  data-src='/static/images/soon.svg'
                  alt="coming soon timer"
                  style={{width: '180px'}}
                />  
              </div>
                            
            </div>
            <div className="col-2 "></div>
          </div>

          <div className="text-center ">
            <EarlyAccessCTA text_colour="dark"/>
          </div>

        </div>

      </LandingLayout>
    )
  }
}

export default withRouter(Page);
