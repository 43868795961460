import React from "react";

import Slide from 'react-reveal/Slide';


export function SectionTitle(props){
  var bgclass = (props.background)? props.background: 'secondary';
  bgclass = "text-" + bgclass;
  
  const firstbg = (props.flip)? "text-black-3": bgclass;
  const secondbg = (props.flip)? bgclass: "text-black-3";

  return (
    <Slide bottom>
      <h1 className="family-h1 text-center py-3">
        <span className={""+firstbg}>{props.first}</span> 
        <span className={""+secondbg}>{" " + props.second}</span>
      </h1>
    </Slide>
  )
}


export function ParaTitle(props){
  var bgclass = (props.background)? props.background: 'secondary';
  bgclass = "text-" + bgclass;
  
  const firstbg = (props.flip)? "text-black-3": bgclass;
  const secondbg = (props.flip)? bgclass: "text-black-3";

  return (
    <Slide bottom>
      <h4 className="family-h5 text-center uppercase text-spacing-2 m-0">
        <span className={firstbg}>{props.first}</span>
        <span className={secondbg}>{props.second}</span>
      </h4>
    </Slide>
  )
}
