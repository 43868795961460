import React from "react";

import {Helmet} from 'react-helmet';

import { SITE } from "./../constant";


export default function Component(props){

  const seo = props.seo || {};

  const description = seo.description || SITE.description;
  // url: window.location.href,
  const url = window.location.href; // seo.url || SITE.url;
  const title = seo.title || SITE.title;
  const page_image = seo.img || SITE.logo;

  return (
    <Helmet>
      <meta name="description" content={description} />
      <meta
        name="robots"
        content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
      />

      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      {/* <meta name="google" content="notranslate"/> */}
      <meta http-equiv="Content-Language" content="en"/>

      <title>{title}</title>

      
      <link rel="canonical" href={url} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content={title} />
      <meta property="og:image" content={page_image} />
      <meta property="og:image:secure_url" content={page_image} />
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="720" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content={page_image} />

    </Helmet>
  )
}

