import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Logo from 'component/assets/logo';
// import EarlyAccessForm from '../reuse/form/early_access';
// import Announcement from 'component/content/announcement';


class Nav extends React.Component {
  render () {
    return(
      <section className={' fixed-top' }>
      {/* <Announcement /> */}
      <nav className={"navbar navbar-expand-lg primary-highlight-3 shadow p-0 bg-blur"}>

        <div className="navbar-header">
          <Link to="/" className="navbar-brand text-spacing-2 mr-2">
            {/* <img style={{height: "40px"}} className="text-white hero mx-2" src="/logo.svg" alt="logo svg" /> */}
            <span className="mx-2 primary-text-1 ">
              <Logo name="logo-no-circle" size={40} />
            </span>
            <span className=" black-text-1 ">Byte</span><span className="primary-text-1">Track</span>
          </Link>  
        </div>

        <button className="navbar-toggler btn btn-primary text-white mr-2" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-bars"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">

          <ul className="navbar-nav mr-auto">
            <li className="nav-item active"></li>
          </ul>

          <ul className="navbar-nav my-0 my-lg-0">
            <li className="nav-item d-flex w-100 align-items-center">
              <a className="btn btn-primary mx-1  white-text-1 my-2 text-spacing-2 uppercase" href="https://blog.bytebeacon.com">Blog</a>
              {/* <Link to="/login" className="btn btn-outline-primary btn-rounded text-spacing-2 m-2 uppercase"><i className="fa fa-sign-in-alt mr-2"></i>Login</Link> */}
            </li>
            <li className="nav-item d-flex w-100 align-items-center">
              <a className="btn btn-primary mx-1  white-text-1 my-2 text-spacing-2 uppercase" href="https://bytetrack-app.bytebeacon.com">Demo</a>
            </li>
            <li className="nav-item">
              
            </li>
            
            <li className="nav-item white-bl-1-2"></li>

            <li className="nav-item d-flex w-100 align-items-center px-1">
              <a className="btn btn-white white-bg-1 p-2 rounded primary-text-1 m-2 text-spacing-2 uppercase" href="https://bytetrack-app.bytebeacon.com/login">
                Login
                {/* <i className="fas fa-paper-plane ml-2 "></i> */}
              </a>
              {/* <div className="m-2"> */}
                {/* <EarlyAccessForm full_width={true} /> */}
              {/* </div> */}
              {/* <NavLink to="/login" className="btn btn-tertiary btn-rounded text-white text-spacing-2 m-2 uppercase"><i className="fa fa-paper-plane mr-2"></i>Start free</NavLink> */}
            </li>
          </ul>

        </div>
      </nav>
      </section>
    )
  }
}


export default withRouter(Nav);
