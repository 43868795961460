import React from 'react';
import Slide from 'react-reveal/Slide';

// import {SectionTitle} from 'component/reuse/title';


const _features = [
  {
    "title": "Invest in you!",
    "description": "Its easy to forget how much you've acomplished", 
    "points": [
      "Keep a log for recurring goals",
      "Make sure the habits, stick for life",
      "Learn a lot about yourself with easy search and insightful analytcs!",
    ], 
    "img": {"src": "/static/images/features/01.png", "alt": "", "size": "md"}
  },
  {
    "title": "Reap benefits!", 
    "description": "Looking back gives you a boost", 
    "points": [
      "Just start tracking and let your trends motivate you",
      "Get meaningful insights to build actionable tasks",
      "Build a history that you're proud to showcase!",
    ], 
    "img": {"src": "/static/images/features/02.png", "alt": "", "size": "md"}
  },
  {
    "title": "Motivation everywhere", 
    "description": "Become the example to follow", 
    "points": [
      "Share your accomplishments with your friends, family and colleagues",
      "Lead them into an improved lifestyle with relatable goals",
      "Learn from people with similar interest, and keep your motivation high",
    ], 
    "img": {"src": "/static/images/features/03.png", "alt": "", "size": "md"}
  },
  {
    "title": "Extend the platform", 
    "description": "Because integrations make things better", 
    "points": [
      "Integrate with your communication tools to make logging effortless",
      "Export data out and analyze it on any platform of your choice",
      "Build on top of our API to extend functionality limited only by your imagination",
    ], 
    "img": {"src": "/static/images/features/04.svg", "alt": "", "size": "sm"}
  },
]

export default class Features extends React.Component {
  render (){
    return (
      <section id="subscribe" className="content-section flex-center ">
        <div className="container-fluid container" >
          {/* <SectionTitle first="What" second="you get" background="primary" /> */}

          <FeatureSection item={_features[0]} flip={true}/>
          <hr className=""/>
          <FeatureSection item={_features[1]} />
          <hr className=""/>
          <FeatureSection item={_features[2]} flip={true}/>
          <hr className=""/>
          <FeatureSection item={_features[3]} />
          
        </div>
      </section>
    )
  }
}


class FeatureSection extends React.Component {
  render (){
    const is_mobile = window.innerWidth < 1200;
    const {item, flip } = this.props;

    const img_width = {
      "sm": 4,
      "md": 6,
      "lg": 8,
    }[item.img.size]

    return (
      // <div className="my-5 py-1">
      <div className="my-5 row">

      {(flip)? '': <FeatureImg item={item} img_width={img_width} flip={flip} />}
        <div className={"d-flex align-items-center col-md-" + (12 - img_width) + ((is_mobile && flip)? ' order-2 my-3': ' my-5')}>
          {/* <Slide bottom> */}
          <div className="flex-left ">
          <h2 className="my-3 black-text-3 text-size-14">{item.title}</h2>
          <h5 className="my-3 black-text-8 text-size-8">{item.description}</h5>
          <ul className="my-3 black-text-5 text-size-3">
            {item.points.map(function(point, idx){
              return(
                <li key={"_"+idx} className="my-2">{point}</li>
              )
            })}
          </ul>
          </div>
          {/* </Slide> */}
        </div>
        {(flip)? <FeatureImg item={item} img_width={img_width} flip={flip} />: ""}

      </div>
      
      // </div>
    )
  }
}

function FeatureImg(props){
  const is_mobile = window.innerWidth < 1200;
  const {item, img_width, flip} = props;
  return(
    <div className={"align-self-center col-md-"+ (img_width) + ((is_mobile && flip)? ' order-1 my-3': ' my-5')}>
      <Slide left={!flip} right={flip}>
        <img className={"img img-fluid rounded p-3" + (is_mobile? ' mx-auto d-block': ' ')} src={item.img.src} alt={item.img.alt} />
      </Slide>
    </div>
  )
}