import React from 'react';
import ReactDOM from 'react-dom';
import {Route, Router, Switch } from 'react-router-dom';

// import {createBrowserHistory} from 'history';
import createBrowserHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';

import LandingPage from './page/public/landing';
import StoryPage from './page/public/story';
import NoMatchPage from './page/generic/no_match';
import ComingSoonPage from './page/generic/coming_soon';

import LegalTOSPage from './page/legal/tos';
import PrivacyPolicyPage from './page/legal/privacy';

import * as serviceWorker from './serviceWorker';

import './styles/bootstrap.min.css';
import './styles/alertify.min.css';


const history = createBrowserHistory();

history.listen(location => {
  // const on_sites = (location.pathname.match(/sites/gi) || []).length;
  // const on_instructions = (location.pathname.match(/instructions/gi) || []).length;
  // const on_no_scroll_page = (on_sites > 0 || on_instructions > 0);
  const on_no_scroll_page = false;
  if (on_no_scroll_page){

  } else {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }  
});


const trackingId = process.env.REACT_APP_GA_CODE;  
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname);


ReactDOM.render(
  <Router history={history}>
    <Switch>
      <Route path='/legal/tos' component={LegalTOSPage} />
      <Route path='/legal/privacy' component={PrivacyPolicyPage} />
      
      {/* Open pages */}
      <Route path='/story' component={StoryPage} />
      <Route path='/documentation' component={ComingSoonPage} />
      <Route path='/login' component={ComingSoonPage} />
      <Route path='/' component={LandingPage} />
      
      {/* Logged in pages */}
      {/* ---- NONE ---- */}

      <Route component={NoMatchPage} />

    </Switch>
  </Router>
  , document.getElementById('page')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
