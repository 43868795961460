import React from 'react';
import {Link} from 'react-router-dom';

import { SubscribeForm } from './../reuse/form_subscription';
import {FooterSectionTitle, FooterExtLink} from './base';
import {CopyrightFooter, CompanyComponent, ReachComponent} from './common';
import SelfPromo from 'component/content/self_promo';
import Logo from 'component/assets/logo';


export function LandingFooter(props){
  const is_mobile = window.innerWidth < 1200;
  return(
    <footer>
      <div  className="p-1 pb-3 dark-bg-1 box-shadow black-text-9">
        <div className="row m-0">
          <div className="col-sm-5 px-0"><AboutComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-0"><CompanyComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-2 px-0"><ProductComponent is_mobile={is_mobile} /></div>
          <div className="col-sm-3 px-0"><ReachComponent is_mobile={is_mobile} /></div>
        </div>
      </div>
      {/* <SelfPromo /> */}
      <CopyrightFooter padding={2} />
    </footer>
  )
}

export function ProductComponent(props){
  const is_mobile = props.is_mobile;
  return (
    <div className={" text-center" + (is_mobile)? ' text-center': " m-1"}>
      <FooterSectionTitle title="Product"/>
      <div className="">
        <FooterExtLink href="https://bytetrack-app.bytebeacon.com" text="Login" />
        <FooterExtLink href="https://bytetrack-app.bytebeacon.com" text="Demo" />
      </div>
    </div>
  )
}


function AboutComponent(props){
  return (
    <div className="">
      <div className="m-1">        
        <div className="mt-5 mb-2 ">
          <Link to="/" className="navbar-brand text-spacing-2 mr-2 text-size-14">
            <span className="mr-2 ">
              <Logo name="logo-no-circle" size={60} />
            </span>
            <span className="text-white">Byte</span><span className="text-primary">Track</span>
          </Link>  
        </div>
        <div className="family-code text-white">
          <p className="">
            {/* 
            I was overweight and wanted to do something about it. 
            I knew that running a few miles everyday will help, I knew daily workouts will help, but not much was sticking   */}
            An easy way for you to track activities and get a report that motivates you further. This is a powerful tool to build habits that stick with you for the long run.
          </p>
        </div>
        
      </div>

      <div className="m-3">
        <SubscribeForm full_width={true} />
      </div>
    </div>
  )
}
