import React from 'react';
import {Helmet} from "react-helmet";

import Nav from 'component/navigation/topbar';
import {LandingFooter} from 'component/footer';
import SEOComponent from 'component/reuse/seo';

export default function LandingLayout(props) {
  const context = props.context || {};

  return (
    <div id={props.id} className="page-wrapper">

      <SEOComponent seo={context.seo}/>

      <Nav />

      <div className="landing-content my-5 text-black-2">
        {props.children}
      </div>

      <LandingFooter />

      <Helmet>
        <script src={"https://slickvid.bytebeacon.com/lib/slickvid/0.1.20221201.01_sv.min.js"}></script>
      </Helmet>

    </div>
  );
}
