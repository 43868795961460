import React from 'react';
import { withRouter} from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

import LandingLayout from 'component/page/landing';
import Hero from 'component/content/hero';
import Pain from 'component/content/pain';
import CTA from 'component/content/cta';
// import SubscribeCTA from 'component/content/subscribe';
import Features from 'component/content/features';
// import EarlyAccessCTA from 'component/content/early_access';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="landing-page">
        <CookieConsent>
          This website uses cookies to enhance the user experience.
        </CookieConsent>

        <Hero />

        <Pain />

        <Features />

        <div className="slant slant-top-left-tertiary"></div>
        <div className="content-section tertiary-bg-1">
          <CTA />
        </div>
        <div className="slant slant-bottom-right-tertiary"></div>

      </LandingLayout>
    )
  }
}

export default withRouter(Page);
