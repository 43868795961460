import React from 'react';
import alertify from 'alertifyjs';

import API, {handleError} from 'utils/xhr';


export class SubscribeForm extends React.Component {

  state = {
    email: '',
  }

  onSubscribe(e){
    e.preventDefault();

    const url = '/public/subscribe';
    const data = {'email': this.state.email}
    API.post(url, data).then(response =>{
      const state = this.state;
      state.email = '';
      alertify.warning('Subscribed!', 1.5);  
      this.setState(state);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  onChange(e){
    const state = this.state;
    state.email = e.target.value;
    this.setState(state);
  }
  
  render (){
    const force_full_width = this.props.full_width || false;
    return (
      <div className="my-3 container flex-center">
        <div className={"input-group p-3 " + ((force_full_width)? ' col-12 ':' col-md-6 ')}>
          <input 
            className="form-control btn-rounded py-3 text-size-2" 
            type="email" 
            placeholder="Email address"
            
            onChange={this.onChange.bind(this)}
            value={this.state.email} />
          <div className="input-group-append">
            <a className="btn input-group-text btn-rounded primary-bg-1 white-text-1" style={{border: '0px'}} href='/' onClick={this.onSubscribe.bind(this)} >Subscribe</a>
          </div>
        </div>
        <p className="my-3 text-spacing-3 small family-code uppercase">for ByteBeacon updates</p>
      </div>
    )
  }
}
