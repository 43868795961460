import React from "react";
import API, {handleError} from 'utils/xhr';


export default class Component extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      api_data: null,
    }
  }

  componentDidMount(){
    if (this.state.api_data !== null){
      return;
    }

    const url = '/configs/bb_social';
    API.get(url).then(response =>{
      const state = this.state;
      state.api_data = response.data.data;
      this.setState(state);
    },(error) =>{
      handleError(error, this.props.history, window.location.pathname);
    })
  }

  render (){
    if (this.state.api_data === null){return '';}

    const social = this.state.api_data;
    const background = this.props.background || ' ';

    return (
      <div className="">
        {social.linkedin ? <a aria-label="linkedin" href={social.linkedin} className={"mx-2 " + background}><i className="fab fa-linkedin-in"></i></a>: null }
        {social.twitter ? <a aria-label="twitter" href={social.twitter} className={"mx-2 " + background}><i className="fab fa-twitter"></i></a>: null }
        {social.facebook ? <a aria-label="facebook" href={social.facebook} className={"mx-2 " + background}><i className="fab fa-facebook-f"></i></a>: null }
        {social.github ? <a aria-label="github" href={social.github} className={"mx-2 " + background}><i className="fab fa-github"></i></a>: null }
        {social.quora ? <a aria-label="quora" href={social.quora} className={"mx-2 " + background}><i className="fab fa-quora"></i></a>: null }
        {social.instagram ? <a aria-label="instagram" href={social.instagram} className={"mx-2 " + background}><i className="fab fa-instagram"></i></a>: null }
        {social.docker ? <a aria-label="docker" href={social.docker} className={"mx-2 " + background}><i className="fab fa-docker"></i></a>: null }
      </div>
    )
  }
}
