import React from "react";

import Logo from 'component/assets/logo';


export default function Component(props){
  return (<GoalsArentImportant/>)
}


function GoalsArentImportant(props){
  const is_mobile = window.innerWidth < 800;
  return (
    <div className="bg-dark text-white text-center py-2">

      <div className="black-text-4">

        <div className={"px-1 my-5 text-spacing-3" + ((is_mobile)? '  text-size-10 ': '  text-size-12 ' )}>
          <span className="primary-text-1 uppercase">Habits </span>
          {'are far more important than '}
          <span className="black-text-6 uppercase">goals</span>
        </div>

        <span className={"text-center my-5 " + ((is_mobile)? '  text-size-5 ': '  text-size-5 ' )}>
          <div className="">
            What's better?
          </div>
          <div className="family-code">
          [
            <span className="black-text-6">goal</span>
          ] Lose 6 lbs in 30 days
          </div>
          <div className="family-code">
          [
            <span className="primary-text-1">habit</span>
          ] Workout 3 times a week
          </div>
        </span>

        <div className="my-5  text-size-4">
          <p className="text-center my-3 ">
            {'A lot of studies show setting '}
            <a href="https://fs.blog/habits-vs-goals" className="black-text-6 underline ">goals can</a>{' '}
            <a href="https://jamesclear.com/goals-systems" className="black-text-6 underline ">negatively</a>{' '}
            <a href="https://psycnet.apa.org/doiLanding?doi=10.1037%2F0022-3514.83.5.1198" className="black-text-6 underline ">impact</a>{' '}
            <a href="https://www.health.harvard.edu/diet-and-weight-loss/lessons-from-the-biggest-loser" className="black-text-6 underline ">your wellbeing</a> 
            <a href="" className=""></a>
          </p>
          {/* The fact is <span className="family-code">recurring events form habits. </span> */}
          <div className="text-center my-3">
            
            <div>You just need to track recurring events in your life. </div>

            <div className="">
              {'Changes that seem small and unimportant at first will compound into remarkable results if you stick with them. - '}
              <a href="https://www.amazon.com/Atomic-Habits-Proven-Build-Break/dp/0735211299" className="black-text-6 underline ">Atomic Habits</a>
            </div>

            <div className={" mt-5 text-spacing-3" + ((is_mobile)? ' text-size-6 ': ' text-size-10 ')}>
              <a href="https://bytetrack-app.bytebeacon.com/login" className="rounded p-3 btn-tertiary white-text-1">
                <span className="mr-2 ">
                  <Logo name="logo-no-circle" size={40} />
                </span>
                ByteTrack
              </a> 
                {' is here to help!'}
            </div>
          </div>

        </div>
        
      </div>

    </div>
  )
}
