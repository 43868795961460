import React from "react";

import Slide from 'react-reveal/Slide';

import { SectionTitle } from 'component/reuse/title';


const ABOUT = [
  {'type': 'text', 'content': "[ define the problem area ]"},
  {'type': 'text', 'content': "But let's face it, [ solution ] is really hard."},
  {'type': 'text', 'content': "[ describe past approaches by Organizations ]"},
  {'type': 'text', 'content': "These efforts might not even yeild the desired results. Furthermore, failed efforts can be painfully frustrating, totally demoralizing and cripplingly expensive."},
  {'type': 'text', 'content': "At ProjectSkeleton, we <strong class=''>know</strong> there's a better way."},
  {'type': 'text', 'content': "At ProjectSkeleton, we're building a platform ... [ describe main offering ]"},
  {'type': 'text', 'content': "[ What customers have to do ]"},
  {'type': 'text', 'content': "Right now we're building the future of [ domain ] .. And it's coming soon to <strong>ProjectSkeleton</strong>!"},
]


function AboutComponentItem(props){
  var aboutItem = props.items;

  if (aboutItem.type === "text"){
    return (<div className="family-p mx-4 my-4 text-wide-2" dangerouslySetInnerHTML={{__html: aboutItem.content }}></div>)
  }

  if (aboutItem.type === "list"){
    var rows = [];
    aboutItem.content.forEach(function(listItem, i){
      rows.push(
        <li key={'about_list_item_'+i} >
            <div className="" dangerouslySetInnerHTML={{__html: listItem }}></div>
        </li>
      );
    });

    return (
        <div>
          <div className="" dangerouslySetInnerHTML={{__html: aboutItem.title }}></div>
          <ul className="">{rows}</ul>
        </div>
    )
  }

  return (<div> SOMETHING WENT WRONG !</div>)
}


export default function AboutComponent(props) {
    const about = {
      content: ABOUT,
      image: {
        path: '/static/images/about.svg',
        alt: '"A new kind of presentation tool that helps you get your product offering across through on site presentations."',
      },
    }

    return (
      <section id="about" className="content-section">
        <div className="slant slant-top-right-secondary-1"></div>
        <div className="content-section highlight-secondary-1">

          <SectionTitle first="Project Skeleton" second="explained" flip={true}/>

          <div className="container-fluid text-black-4">

            <div className="row">
              <div className="col-sm-4 flex-center d-none d-lg-block ">
                <div className="img img-fluid">
                  <Slide left>
                    <img className="hero" src={about.image.path} alt={about.image.alt} />
                  </Slide>
                </div> 
              </div>

              <div className="col-sm-8">
                {about.content.map(function(aboutItem, i){
                  return (
                    <Slide bottom>
                      <AboutComponentItem items={aboutItem} key={'about_item_' + i}/>
                    </Slide>
                  )
                })}
              </div>

            </div>              
          </div>

        </div>
        <div className="slant slant-bottom-left-secondary-1"></div>
      </section>
    )
}