import React from 'react';
import { withRouter} from 'react-router-dom';

import LandingLayout from 'component/page/landing';
import About from 'component/content/about';


class Page extends React.Component {
  render (){
    return (
      <LandingLayout id="landing-page">
        <About />
      </LandingLayout>
    )
  }
}

export default withRouter(Page);
